import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = ({data}) => {


  return (
    <Layout>
      <SEO title="Personvern | Gratis.no" description={""}/>

      <div>
        <section className="section novi-bg novi-bg-img section-lg bg-white section-top-shadow">
          <div className="container privacyPage">
            <h2 style={{marginBottom:'30px'}} className="text-center text-sm-left">Gratis.no <span className="h2-style">personvern</span></h2>


              <h4>Personvernserklæring</h4>

              <p className="infoPageParagraph">
                <strong>Vi gjør vårt beste for å ivareta ditt personvern</strong>
              </p>

              <p className="infoPageParagraph">
                For at vi i Wonder Digital Media AS (“Wonder”) blant annet skal kunne gi deg gode og verdiøkende tjenester er det noen ganger nødvendig at
                tjenesten vår innhenter opplysninger om deg, dog utelukkende ved ditt eksplisitte samtykke. Wonder ved daglig leder opptrer som behandlingsansvarlig, og
                følger den til enhver tid gjeldende lovgivning og retningslinjer fra Datatilsynet.
              </p>

              <p className="infoPageParagraph">
                Innholdet i tjenesten er nærmere beskrevet i våre brukervilkår. Brukervilkårene inneholder også generelle vilkår og betingelser for bruk
                av tjenestene. Du kan lese våre <Link to="/brukerbetingelser/">brukervilkår her</Link>.
              </p>

              <h4>Personopplysninger som behandles</h4>
              <p className="infoPageParagraph">
                Ved bruk av Wonder sine tjenester kan Wonder samle inn, lagre, utlevere og bruke følgende personopplysninger om deg: navn, e-postadresse og
                telefonnummer.{/*, registreringsnummer til bil, fødselsdato/fødselsnummer, bostedsadresse og kredittopplysninger.*/}
              </p>

              <h4>Formål og behandlingsgrunnlag, innhenting og utlevering av personopplysninger</h4>
              <p className="infoPageParagraph">
                Wonder behandler personopplysninger og andre data med formål om å lage en best mulig tjeneste og brukeropplevelse for deg.
              </p>

            {/*<p className="infoPageParagraph">
                Ved ditt samtykke innhenter vi og oversender data som er nødvendig for å generere forsikringstilbud til deg fra ett eller flere
                forsikringsselskaper, og i forbindelse med kjøp kan vi også innhente kredittvurderingsopplysninger om deg i tillegg til å videreformidle
                den informasjonen som trengs for å opprette et nytt kundeforhold i det selskapet du har valgt å kjøpe forsikring fra. For nærmere
                informasjon om behandling av personopplysninger i forbindelse med bilforsikring, se våre brukervilkår punkt 5.
              </p>*/}

              <p className="infoPageParagraph">
                Vi lagrer også data slik at vi kan hjelpe deg i bestillingsprosessen om nødvendig, eller hente frem tidligere tilbud for deg ved en
                senere anledning. Dette gjor at neste gang du bruker Gratis.no behøver du ikke nødvendigvis å oppgi/lete opp all informasjon igjen. Slik jobber
                Wonder for deg. Data kan lagres både hos Wonder, men også lokalt på din egen datamaskin i form av såkalte nettkapsler (“cookies”).
              </p>
              {/*<p className="infoPageParagraph">
                Wonder behandler også personopplysninger til andre formål, som enten er tillatt eller pålagt oss ved lov, regler, forskrifter eller
                andre rettslige prosesser, eller der vi underretter deg spesielt.
              </p>*/}
              <p className="infoPageParagraph">
                Behandling og eller lagring av personopplysninger forutsetter samtykke fra deg, eller annet rettslig grunnlag. Samtykke kan være gitt
                etter forespørsel fra oss og/eller ved at du frivillig sender informasjonen til oss via vårt nettsted eller andre relevante plattformer
                for tjenesten. Det er frivillig å oppgi personopplysninger. Du kan også når som helst trekke ditt samtykke tilbake.
              </p>
              <p className="infoPageParagraph">
                Informasjonen om deg hentes primært fra deg selv, gjennom valg du gjør på nettsiden. {/*Ved tilbud om forsikring bruker vi også eksterne kilder
                etter samtykke fra deg slik at du skal slippe å taste inn unødvendig mye informasjon.*/} Eventuell tredjepartsinformasjon vil du naturligvis bli bedt
                om å bekrefte, slik at du kan være trygg på at dataene vi benytter er korrekte. Samtidig vil du bli informert
                om hvor dataene er hentet fra og om det er en offentlig kilde.
              </p>
            {/*<p className="infoPageParagraph">
                Wonder vil også kunne utlevere dine personopplysninger til leverandører og underleverandører som utfører tjenester for Wonder.
                Leverandører og underleverandører vil kun bruke personopplysningene til det formål å utføre sine oppgaver på vegne av Wonder.
              </p>*/}

              <h4>Sikkerhet</h4>
              <p className="infoPageParagraph">
                Gratis.no kommuniserer over en sikker protokoll for nettkommunikasjon kalt SSL /HTTPS. Dette innebærer at alle data sendes kryptert
                mellom vår server og nettleseren din, uten at noen kan avlese dataene på veien. Se etter hengelåsikonet i adresselinjen for mer
                informasjon om sikkerhetssertifikatene vi bruker. Tjenesten gratis.no og ligger på en fysisk nettjener, og tilgang til databasen
                er det kun et fåtall personer med tjenstlig behov hos Wonder med underleverandører som har.
              </p>
              <p className="infoPageParagraph">
                Alt i alt sørger dette for at det skal være så trygt som mulig å legge inn personlige data i vår tjeneste.
              </p>

              <h4>Sletting</h4>
              <p className="infoPageParagraph">
                Når behandling av personopplysninger ikke lenger er nødvendig for å oppfylle formålet med behandlingen eller dersom du trekker ditt samtykke tilbake vil Wonder slette eller anonymisere dataene slik at individer ikke lenger kan identifiseres, i tråd med Personopplysningsloven. Anonymiserte data vil brukes for statistiske formål og egenanalyse.
              </p>

              <h4>Rettigheter</h4>
              <p className="infoPageParagraph">
                Du har rett til innsyn, retting, sletting, dataportabilitet og motsette deg visse former av behandling i henhold til den enhver tid gjeldende personopplysningslov.
              </p>
              <p className="infoPageParagraph">
                Du har også rett til å klage til Datatilsynet på behandling som er i strid med de til enhver tid gjeldende personvernregler.
              </p>


              <h4>Informasjonskapsler</h4>
              <p className="infoPageParagraph">
                Wonder benytter informasjonskapsler (cookies) på våre nettsider. Opplysninger og innloggingsdata kan lagres på din egen maskin, og brukes av
                Wonder i den hensikt å forbedre brukeropplevelsen neste gang du besøker siden.
              </p>

            <p className="infoPageParagraph">
              Tredjepartsinformasjonskapsler kan også forekomme. Eksempler på aktører som bruker cookies gjennom våre sider er Facebook, Google samt
              ulike annonsenettverk som Tradedoubler og Tradetracker. Disse informasjonskapslene
              brukes til å vise mest mulig relevant innhold for deg og data behandles av de respektive tredjeparter.
            </p>


            <h4>Avmelding</h4>
            <p className="infoPageParagraph">
              Dersom du ønsker å melde deg av våre epost- eller SMS-varslinger, vennligst <Link to="/avmelding/">trykk her</Link>.
            </p>


            <h4>Kontaktinformasjon</h4>
              <p className="infoPageParagraph">
                Dersom du skulle ha spørsmål knyttet til våre rutiner og sikkerhet, de personopplysningene vi har lagret om deg, eller ønsker å trekke tilbake ditt samtykke eller utøve dine rettigheter ta gjerne kontakt med oss på:
              </p>

              <p className="infoPageParagraph">
                Wonder Digital Media AS<br/>
                post@wonder.no<br/>
                Nerstadveien 25<br/>
                3350 Prestfoss<br/>
              </p>


            <ul className="infoPageList">
              <li><Link to="/brukerbetingelser/">Vilkår for bruk av denne siden</Link></li>
            </ul>

            <div className="mt-xl-40 mt-50 text-center text-sm-right"><Link
              className="button button-icon button-icon-right button-black" to="/"><span
              className="icon novi-icon fa fa-chevron-right"/>Til forsiden</Link></div>
          </div>
        </section>

      </div>
    </Layout>
  )
}

export default PrivacyPage


